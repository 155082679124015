<template>
  <drawer
    :is-open="isOpen"
    @close-drawer="closeDrawer">
    <template #header>
      <h3
        class="typeset-3"
        style="line-height: 32px">
        {{ t("favourites.drawer.title") }}
      </h3>
    </template>
    <template #content>
      <mini-favourites-listings @close-drawer="closeDrawer" />
    </template>
  </drawer>
</template>

<script>
import Drawer from "@/app/javascript/components/shared/Drawer.vue";
import MiniFavouritesListings from "../components/miniFavourites/MiniFavouritesListings.vue";

export default {
  name: "FavouritesDrawer",
  components: {
    Drawer,
    MiniFavouritesListings
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    window.addEventListener("openFavouritesDrawer", this.openDrawer);
  },
  methods: {
    closeDrawer() {
      this.isOpen = false;
    },
    openDrawer() {
      this.isOpen = true;
    }
  }
};
</script>

import { createApp } from "vue";

import I18n from "../plugins/i18n";
import UnpersonalisedProducts from "../components/listings/UnpersonalisedProducts.vue";
import { ready } from "../utils/listeners";

window.setupUnpersonalisedProducts = () => {
  const node = document.getElementById("unpersonalised-products");
  if (!node) return;

  const props = JSON.parse(node.dataset.props);
  const translations = JSON.parse(node.dataset.translations);

  const app = createApp(UnpersonalisedProducts, props);

  app.use(I18n, translations);

  app.mount(node);
};

ready(() => {
  window.setupUnpersonalisedProducts();
});

import { createApp } from "vue";
import ProductCarousel from "@/app/future_admin/cms/landing_page/blocks/ProductCarousel.vue";
import { ready } from "../utils/listeners";

ready(() => {
  document
    .querySelectorAll(".product-carousels-landing-page-block")
    .forEach(node => {
      const props = JSON.parse(node.dataset.props);
      const app = createApp(ProductCarousel, props);

      app.mount(node);
    });
});

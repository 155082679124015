<template>
  <div v-on-click-outside="closeDrawer">
    <mini-favourites-toggle @toggle="isOpen = !isOpen" />
  </div>
</template>

<script>
import { vOnClickOutside } from "@vueuse/components";

import MiniFavouritesToggle from "../components/miniFavourites/MiniFavouritesToggle.vue";

export default {
  name: "MiniFavourites",
  directives: {
    onClickOutside: vOnClickOutside
  },
  components: {
    MiniFavouritesToggle
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    window.addEventListener("openFavouritesDrawer", this.openDrawer);
  },
  methods: {
    closeDrawer() {
      this.isOpen = false;
    }
  }
};
</script>

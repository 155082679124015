<template>
  <div class="pt-5">
    <h3 class="typeset-3">{{ name }}</h3>
    <div
      v-for="(jobs, locationName) in locations"
      :key="keyForLocation(locationName)">
      <h3 class="typeset-6 mt-5 mb-3 ml-2">{{ locationName }}</h3>
      <job
        v-for="job in jobs"
        :key="job.id"
        :job="job" />
    </div>
  </div>
</template>

<script>
import Job from "./Job.vue";

export default {
  name: "Department",
  components: {
    Job
  },
  props: {
    locations: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    keyForLocation() {
      return locationName => `${this.name}-${locationName}`;
    }
  }
};
</script>

import { ready } from "../utils/listeners";

ready(() => {
  document.querySelectorAll(".js-dropdown-menu-block-cta").forEach(cta => {
    cta.addEventListener("click", e => {
      e.preventDefault();
      window.location = e.target.previousElementSibling.value;
    });
  });
});

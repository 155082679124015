<template>
  <div
    class="js-impression-list"
    data-situation="pdp_add_ons_carousel"
    :data-listing-id="analyticsId">
    <div
      v-if="perfectPartners.length > 3"
      class="-mx-4 sm:mx-0 py-7">
      <p class="typeset-3 md:typeset-2 mb-6 px-4 sm:px-0">
        {{ t("stock-items.perfect-partners.more-to-love") }}
      </p>
      <div
        :class="`md:grid-cols-${perfectPartners.length}`"
        class="flex md:grid gap-5 overflow-x-auto md:overflow-hidden px-4 sm:px-0">
        <div
          v-for="upsell in perfectPartners"
          :key="upsell.id"
          class="flex flex-col flex-no-shrink w-2/5 sm:w-4/5 md:w-auto">
          <div
            class="product flex flex-col text-center h-full"
            :data-id="upsell.analytics_id">
            <div class="relative mb-3 bg-paper-3">
              <div
                v-tippy="favouriteTippy(upsell)"
                :class="{ active: isFavourited(upsell) }"
                class="flex absolute favourite-product-container js-favourite-container favourite-listing-container p-3 items-center justify-center text-paper-6 z-1 cursor-pointer bottom-0 right-0"
                :data-id="upsell.id"
                :data-analytics-id="`${upsell.id}`"
                data-cy="favourite-heart"
                :data-price="upsell.price.formatted_raw_price"
                data-type="StockItem">
                <heart-filled-svg v-if="isFavourited(upsell)" />
                <heart-svg v-else />
              </div>
              <img
                :src="upsell.image"
                class="img-responsive mb-3 cursor-pointer aspect-ratio-1x1"
                @click.prevent="openModal(upsell)" />
            </div>
            <div class="flex flex-col h-full justify-between">
              <h3 class="mb-1 typeset-6">
                {{ upsell.name }}
              </h3>
              <div>
                <div class="flex flex-no-wrap justify-center gap-3 mb-4">
                  <h2 class="typeset-6 flex-no-shrink">
                    {{ upsell.price.actual }}
                  </h2>
                  <h2
                    v-if="upsell.price.has_discount"
                    class="strikethrough bg-sunflower flex-no-shrink px-2 py-1">
                    {{ upsell.price.base }}
                  </h2>
                </div>
                <div class="mb-4">
                  <a
                    href="#"
                    class="underline inline-block"
                    :aria-label="`Find out more about ${upsell.name}`"
                    @click.prevent="openModal(upsell)">
                    {{ t("stock-items.perfect-partners.find-out-more") }}
                  </a>
                </div>
                <btn
                  :promise="addStockItem(upsell)"
                  type="secondary"
                  :aria-label="`Add ${upsell.name} to basket`"
                  class="btn-block px-3 w-full">
                  {{ t("stock-items.perfect-partners.add-to-basket") }}
                </btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <upsell-modal
        v-if="modalOpen"
        :upsells="[activePartner]"
        :upsell="activePartner"
        :close-modal="closeModal"
        perfect-partners>
        <btn
          :promise="addStockItem(activePartner)"
          class="btn-block btn btn-large">
          {{ t("stock-items.perfect-partners.add-to-basket") }}
        </btn>
      </upsell-modal>
    </div>
  </div>
</template>

<script>
import stockItem from "@/app/javascript/api/stockItem";

import Btn from "@/app/javascript/components/shared/Btn.vue";
import UpsellModal from "@/app/javascript/components/addOns/extras/multiple/UpsellModal.vue";
import perfectPartners from "@/app/javascript/api/perfectPartners";
import HeartFilledSvg from "./HeartFilledSvg.vue";
import HeartSvg from "./HeartSvg.vue";

export default {
  name: "PerfectPartners",
  components: {
    Btn,
    UpsellModal,
    HeartFilledSvg,
    HeartSvg
  },
  props: {
    isStockItem: {
      type: Boolean,
      default: false
    },
    listingId: {
      type: Number,
      required: false
    },
    analyticsId: {
      type: String,
      required: true
    },
    productTypeNameId: {
      type: Number,
      required: false
    },
    location: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      perfectPartners: [],
      activePartner: null,
      favouritedListings: [],
      modalOpen: false
    };
  },
  computed: {
    isFavourited() {
      return item => {
        return this.favouritedListings.some(
          ([type, id]) => type === "StockItem" && id === parseInt(item.id, 10)
        );
      };
    },
    favouriteTippy() {
      return upsell => {
        return {
          content: this.tippyText(upsell),
          theme: "dark",
          placement: "top",
          arrow: true,
          animation: "shift-away",
          touch: false
        };
      };
    },
    tippyText() {
      return upsell => {
        return this.isFavourited(upsell)
          ? this.t("favourites.remove-from-favourites")
          : this.t("favourites.add-to-favourites");
      };
    },
    currentPartner() {
      return this.perfectPartners.find(
        partner => partner.id === this.currentPartnerId
      );
    }
  },
  mounted() {
    this.fetchPerfectPartners().then(() => {
      this.$nextTick(() => {
        const htmlChanged = new Event("htmlChanged");
        window.dispatchEvent(htmlChanged);
      });
    });
    this.setUpFavouritesListener();
  },
  methods: {
    setUpFavouritesListener() {
      window.addEventListener(
        "addedFavourite",
        this.updateFavourites.bind(this)
      );
      window.addEventListener(
        "removedFavourite",
        this.updateFavourites.bind(this)
      );
    },
    updateFavourites() {
      this.favouritedListings = [...window.favouritedListings];
    },
    async fetchPerfectPartners() {
      const response = await perfectPartners.index(
        this.isStockItem,
        this.listingId,
        this.productTypeNameId
      );
      this.perfectPartners = response.data.perfect_partners;
      this.setCurrentPartner();
      window.resetFavourites();
      this.favouritedListings = window.favouritedListings;
    },
    setActivePartner(partner) {
      analytics.track("Perfect Partner Open Modal", {
        stock_item_id: partner.id
      });
      this.activePartner = partner;
      setTimeout(window.setUpUpsellFlickity, 100);
    },
    openModal(upsell) {
      this.setActivePartner(upsell);
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    addStockItem(partner) {
      return () => {
        return new Promise(resolve => {
          analytics.track("Perfect Partner Add To Cart", {
            stock_item_id: partner.id
          });
          stockItem
            .addToCart({
              stockItem: partner,
              quantity: 1,
              isPerfectPartner: true,
              analyticsId: this.analyticsId
            })
            .then(({ data }) => {
              this.closeModal();
              if (data.require_login) {
                const showLoginModalEvent = new Event("showLoginModal");
                window.dispatchEvent(showLoginModalEvent);
              } else if (this.location === "basket") {
                window.history.scrollRestoration = "manual";
              } else {
                window.history.scrollRestoration = "auto";
              }
              window.location.reload();
              resolve();
            });
        });
      };
    },
    setCurrentPartner() {
      [this.currentPartnerId] = this.perfectPartners.map(partner => partner.id);
    }
  }
};
</script>

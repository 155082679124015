import { on } from "../utils/listeners";

on("click", "[data-toggle='collapse']", (el, e) => {
  e.preventDefault();
  const target = document.querySelector(el.getAttribute("href"));

  target.style.height = `${target.scrollHeight}px`;
  target.style.height = `${target.scrollHeight}px`;

  if (target.classList.contains("in")) {
    target.classList.remove("in");
    target.style.height = 0;
    el.classList.add("collapsed");
  } else {
    target.classList.add("in");
    target.style.height = `${target.scrollHeight}px`;
    el.classList.remove("collapsed");

    // Workaround for issue with "nested" collapsible elements
    // e.g. filters with "+ more" control
    setTimeout(() => {
      target.style.height = "auto";
    }, 500);
  }
});

on("click", "[data-toggle='collapse-comparison']", (el, e) => {
  e.preventDefault();
  const target = document.querySelector(el.getAttribute("href"));
  const { collapsedHeight } = target.dataset;
  const seeFullTableMessage = document.querySelector(".see-full-table");
  const closeTableMessage = document.querySelector(".close-table");

  target.style.height = `${target.scrollHeight}px`;

  if (target.classList.contains("in")) {
    target.classList.remove("in");
    seeFullTableMessage.classList.remove("hidden");
    closeTableMessage.classList.add("hidden");
    target.style.height = `${collapsedHeight || 0}px`;
    el.classList.add("collapsed");
  } else {
    target.classList.add("in");
    target.style.height = `${target.scrollHeight}px`;
    el.classList.remove("collapsed");
    seeFullTableMessage.classList.add("hidden");
    closeTableMessage.classList.remove("hidden");

    // Workaround for issue with "nested" collapsible elements
    // e.g. filters with "+ more" control
    setTimeout(() => {
      target.style.height = "auto";
    }, 500);
  }
});

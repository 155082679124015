import { ready } from "../utils/listeners";

ready(() => {
  const videos = document.querySelectorAll("video.lazy");
  const videoObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const video = entry.target;
        const videoSourceElements = video.querySelectorAll("source");
        if (videoSourceElements.length === 0) {
          video.src = video.dataset.src;
        } else {
          videoSourceElements.forEach(source => {
            source.src = source.dataset.src;
          });
        }
        video.load();
        video.play();
        video.classList.remove("lazy");
        videoObserver.unobserve(video);
      }
    });
  });

  videos.forEach(video => {
    videoObserver.observe(video);
  });
});

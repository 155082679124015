<template>
  <svg
    class="icon-faveheart"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 0C12.75 0 11.1001 0.82838 10 2.17446C8.89986 0.82838 7.25 0 5.5 0C2.3999 0 0 2.48489 0 5.6948C0 9.62919 3.3999 12.7875 8.54981 17.6536L10 19L11.4502 17.6536C16.6 12.7875 20 9.62914 20 5.6948C20 2.48489 17.6001 0 14.5 0ZM10.6171 16.2914L10.4143 16.483L10 16.8676L9.58577 16.483L9.38317 16.2916C6.95923 14.0016 4.86582 12.0238 3.48192 10.2554C2.13784 8.53788 1.53846 7.13133 1.53846 5.6948C1.53846 4.56346 1.94337 3.52114 2.67861 2.7599C3.41139 2.00118 4.41337 1.58333 5.5 1.58333C6.75644 1.58333 7.99856 2.18535 8.8226 3.19363L10 4.63427L11.1774 3.19363C12.0014 2.18535 13.2435 1.58333 14.5 1.58333C15.5867 1.58333 16.5886 2.00118 17.3214 2.75985C18.0566 3.52114 18.4615 4.56341 18.4615 5.6948C18.4615 7.13133 17.8622 8.53783 16.5182 10.2553C15.1343 12.0237 13.041 14.0014 10.6171 16.2914Z"
      fill="currentColor" />
  </svg>
</template>

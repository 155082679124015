import { createStore } from "vuex";

import user from "./modules/user";

export default createStore({
  modules: {
    user
  },
  state: {
    loading: false,
    stores: [],
    currentUser: null,
    showReferAFriend: false,
    showDwt: false,
    hideCredit: false,
    credit: 0,
    creditLabel: null,
    oAuthProviders: null,
    adminUser: false,
    dropdownPaths: null,
    newPasswordPath: null,
    loginModalShowing: false,
    registerModalShowing: false,
    autoOptIn: false
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setStores(state, stores) {
      state.stores = stores;
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    setShowReferAFriend(state, showReferAFriend) {
      state.showReferAFriend = showReferAFriend;
    },
    setShowDwt(state, showDwt) {
      state.showDwt = showDwt;
    },
    setHideCredit(state, hideCredit) {
      state.hideCredit = hideCredit;
    },
    setCredit(state, credit) {
      state.credit = credit;
    },
    setCreditLabel(state, creditLabel) {
      state.creditLabel = creditLabel;
    },
    setOAuthProviders(state, oAuthProviders) {
      state.oAuthProviders = oAuthProviders;
    },
    setDropdownPaths(state, dropdownPaths) {
      state.dropdownPaths = dropdownPaths;
    },
    setNewPasswordPath(state, newPasswordPath) {
      state.newPasswordPath = newPasswordPath;
    },
    setLoginModalShowing(state, flag) {
      state.loginModalShowing = flag;
    },
    setRegisterModalShowing(state, flag) {
      state.registerModalShowing = flag;
    },
    setAdminUser(state, adminUser) {
      state.adminUser = adminUser;
    },
    setAutoOptIn(state, flag) {
      state.autoOptIn = flag;
    }
  }
});

import Flickity from "flickity-fade";
import "flickity-imagesloaded";
import tippy from "tippy.js";
import { on, ready } from "../utils/listeners";
import listingTemplateForTooltip from "../utils/listingTemplateForTooltip";

const setupDesktopBeacons = () => {
  const beacons = document.querySelectorAll(".tooltip-beacon");

  beacons.forEach(beacon => {
    const beaconData = JSON.parse(beacon.dataset.beacon);
    const listingData = JSON.parse(beacon.dataset.listing);
    tippy(beacon, {
      content: listingTemplateForTooltip(listingData),
      interactive: true,
      theme: "light",
      arrow: false,
      hideOnClick: false,
      placement: beaconData.placement,
      allowHTML: true
    });

    beacon.addEventListener("click", e => {
      analytics.track("Clicked Shoppable Beacon");

      if (e.metaKey) {
        e.preventDefault();
        window.open(e.target.href, "_blank");
      } else {
        const linkToFollow = e.target.href;
        window.location = linkToFollow;
      }
    });
  });
};

window.initializeShoppableImages = () => {
  // Loop through and set up any product carousels
  // (product page, landing page, the fold)
  const carousels = document.querySelectorAll(".shoppable-image-products");

  const flktys = [];

  carousels.forEach(carousel => {
    const options = {
      prevNextButtons: false,
      pageDots: false,
      draggable: false,
      fade: true,
      imagesLoaded: true,
      cellSelector: ".shoppableImageProduct"
    };

    const newFlkty = new Flickity(carousel, options);
    flktys.push(newFlkty);

    flktys.forEach(flkty => {
      flkty.resize();
    });

    on("click", ".beacon", (el, e) => {
      e.preventDefault();
      if (el.classList.contains("selected")) return;

      const beacons = el.parentElement.querySelectorAll(".beacon");

      beacons.forEach(beacon => {
        beacon.classList.remove("selected");
      });

      el.classList.add("selected");

      const { beaconIndex } = el.dataset;
      flktys.forEach(flkty => {
        flkty.select(beaconIndex);
      });
    });
  });
};

ready(() => {
  window.initializeShoppableImages();
  setupDesktopBeacons();
});

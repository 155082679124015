<template>
  <div v-if="canShow">
    <div class="radio-button-section mb-6">
      <a
        :class="{ 'js-variant-option': !switchIsUnpersonalised }"
        :href="personalisedHref"
        @click.prevent="personalisedTracking">
        <radio-input
          class="mb-3 border border-ink inline-flex inline-with-cta p-3"
          :current-value="selectedOption"
          value="personalised"
          input-id="personalised-unpersonalised-radio"
          :label="t('unpersonalised-products.personalised-label')"
          label-class="cursor-pointer typeset-7 gap-x-3">
          <template #description>
            <p
              class="typeset-8"
              v-html="personalisedArrivalDescription"></p>
          </template>
        </radio-input>
      </a>
      <a
        :class="{ 'js-variant-option': switchIsUnpersonalised }"
        :href="unpersonalisedHref"
        @click.prevent="unpersonalisedTracking">
        <radio-input
          class="border border-ink inline-flex inline-with-cta p-3"
          :current-value="selectedOption"
          value="unpersonalised"
          input-id="personalised-unpersonalised-radio"
          :label="t('unpersonalised-products.unpersonalised-label')"
          label-class="cursor-pointer typeset-7 gap-x-3">
          <template #description>
            <p
              class="typeset-8"
              v-html="unpersonalisedArrivalDescription"></p>
          </template>
        </radio-input>
      </a>
    </div>
  </div>
</template>

<script>
import RadioInput from "@/app/javascript/components/shared/RadioInput.vue";

export default {
  name: "UnpersonalisedProducts",
  components: {
    RadioInput
  },
  props: {
    switchAnalyticsId: {
      type: String,
      required: true
    },
    switchUrl: {
      type: String,
      required: true
    },
    switchIsUnpersonalised: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedOption: "personalised",
      personalisedArrivalDate: null,
      unpersonalisedArrivalDate: null
    };
  },
  computed: {
    canShow() {
      return this.personalisedArrivalDate && this.unpersonalisedArrivalDate;
    },
    personalisedArrivalDescription() {
      return this.t("unpersonalised-products.personalised-shipping-html", {
        date: this.personalisedArrivalDate
      });
    },
    unpersonalisedArrivalDescription() {
      return this.t("unpersonalised-products.unpersonalised-shipping-html", {
        date: this.unpersonalisedArrivalDate
      });
    },
    personalisedHref() {
      return this.switchIsUnpersonalised ? "#" : this.switchUrl;
    },
    unpersonalisedHref() {
      return this.switchIsUnpersonalised ? this.switchUrl : "#";
    }
  },
  mounted() {
    window.addEventListener("message", this.assignShippingData, false);
    this.selectedOption = this.switchIsUnpersonalised
      ? "personalised"
      : "unpersonalised";
  },
  methods: {
    assignShippingData({ data }) {
      if (data.type === "shippingTimesSet") {
        this.setShippingTimes(data.shippingInfo);
      }
    },
    personalisedTracking() {
      if (this.switchIsUnpersonalised) return;

      analytics.track("Selected Personalised Radio Button", {
        productId: this.switchAnalyticsId
      });
    },
    unpersonalisedTracking() {
      if (!this.switchIsUnpersonalised) return;

      analytics.track("Selected Unpersonalised Radio Button", {
        stockItemId: this.switchAnalyticsId
      });
    },
    setShippingTimes(shippingInfo) {
      if (!shippingInfo.switchable_variant) {
        this.personalisedArrivalDate = shippingInfo.delivery_date;
        return;
      }

      if (shippingInfo.switchable_variant.personalised) {
        this.personalisedArrivalDate =
          shippingInfo.switchable_variant.data.delivery_date;
        this.unpersonalisedArrivalDate = shippingInfo.delivery_date;
      } else {
        this.personalisedArrivalDate = shippingInfo.delivery_date;
        this.unpersonalisedArrivalDate =
          shippingInfo.switchable_variant.data.delivery_date;
      }
    }
  }
};
</script>

import { createApp } from "vue";
import { ready } from "../utils/listeners";

import MentionMe from "../components/mentionMe/MentionMe.vue";
import I18n from "../plugins/i18n";

ready(() => {
  const node = document.getElementById("mention-me-app");
  if (!node) return;

  const props = JSON.parse(node.dataset.props);
  const translations = JSON.parse(node.dataset.translations);
  const { offerPayload, location, offerApiUrl } = props.mentionMe;

  if (!offerApiUrl) return;

  const mountApp = data => {
    const app = createApp(MentionMe, {
      name: "MentionMe",
      mentionMeData: data,
      location,
      isGuestCheckout: props.isGuestCheckout
    });

    app.use(I18n, translations);

    app.mount(node);
  };

  const callOfferApi = () => {
    fetch(offerApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(offerPayload)
    })
      .then(response => response.json())
      .then(mountApp);
  };

  callOfferApi();
});

import api from "./index";

export default {
  async index(isStockItem, listingId, productTypeNameId) {
    return api.post("/perfect_partners.json", {
      is_stock_item: isStockItem,
      listing_id: listingId,
      product_type_name_id: productTypeNameId
    });
  }
};

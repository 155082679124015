import SavedDesignsBlock from "@/app/future_admin/cms/landing_page/blocks/SavedDesignsBlock.vue";
import { createApp } from "vue";
import responsiveMode from "@/app/javascript/utils/responsiveMode";
import { ready } from "../utils/listeners";

ready(() => {
  if (document.body.classList.contains("can-show-saved-designs")) {
    document
      .querySelectorAll(".saved-designs-landing-page-block")
      .forEach(node => {
        const props = {
          ...JSON.parse(node.dataset.props),
          responsiveMode: responsiveMode()
        };
        const app = createApp(SavedDesignsBlock, props);

        app.mount(node);
      });
  }
});

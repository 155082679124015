class BannerSlider {
  constructor(bannerContainer) {
    this.bannerContainer = bannerContainer;
    this.changeStoreBanner = document.getElementById("change-store-bar");
    this.bannerLinks = document.querySelectorAll(".js-banner-link");
    this.bannerWidth =
      this.bannerContainer.querySelector(".inner-banner").offsetWidth;
    this.bannersLength =
      this.bannerContainer.querySelectorAll(".inner-banner").length;

    this.stopActions = false;
    this.startTime = Date.now();
    this.remainingTime = 0;
    this.pauseBannerOffscreen = false;

    if (!!this.changeStoreBanner && !!this.bannerContainer) {
      document.body.classList.add("muti-banner-change-store");
    }
  }

  init() {
    if (this.bannersLength > 1) {
      this.addEventListeners();
      this.initiateBannerScroll();
      this.setupObserver();
    }
  }

  addEventListeners = () => {
    this.bannerContainer.addEventListener("mouseenter", this.pauseBannerShift);
    this.bannerContainer.addEventListener("mouseleave", this.resumeBannerShift);

    window.addEventListener("resize", this.checkBannerWidthOnResize);

    this.bannerLinks.forEach(link => {
      link.addEventListener("click", () => {
        analytics.track("Clicked Promo Banner");
      });
    });

    const rightButton = document.getElementById("right-banner-button");
    const leftButton = document.getElementById("left-banner-button");

    rightButton.addEventListener("click", this.shiftBannerRightClick);

    leftButton.addEventListener("click", this.shiftBannerLeftClick);
  };

  setupObserver = () => {
    const callback = entries => {
      if (!entries[0].isIntersecting) {
        this.pauseBannerOffscreen = true;
      } else {
        this.pauseBannerOffscreen = false;
      }
    };

    const observer = new IntersectionObserver(callback);

    observer.observe(this.bannerContainer);
  };

  initiateBannerScroll = () => {
    // This kicks off the first banner scroll with a 4 second timer
    this.bannerInterval = setInterval(this.shiftBannerLeft, 4000);
  };

  shiftBannerLeft = () => {
    if (this.stopActions) return;
    if (this.pauseBannerOffscreen) return;

    // We don't want users to keep spamming the left / right buttons, so the stopAction var will be truthy whenever the banner is in motion
    this.stopActions = true;

    this.bannerContainer.style.transition = "transform 0.5s ease";
    this.bannerContainer.style.transform = `translateX(-${this.bannerWidth}px)`;

    this.bannerContainer.addEventListener(
      "transitionend",
      () => {
        this.bannerContainer.style.transition = "none";
        this.bannerContainer.style.transform = `translateX(0)`;
        const firstBanner = this.bannerContainer.querySelector(".inner-banner");

        this.bannerContainer.appendChild(firstBanner); // Moves the first banner to the end

        this.handleTransitionEnd();
      },
      { once: true }
    );
  };

  shiftBannerRight = () => {
    if (this.stopActions) return;
    if (this.pauseBannerOffscreen) return;

    this.stopActions = true;

    this.bannerContainer.style.transition = "none";

    const bannerLength =
      this.bannerContainer.querySelectorAll(".inner-banner").length;

    const currentVisibleBanner =
      this.bannerContainer.querySelector(".inner-banner");
    const nextVisibleBanner =
      this.bannerContainer.querySelectorAll(".inner-banner")[bannerLength - 1];

    this.bannerContainer.style.left = `-${this.bannerWidth}px`;
    this.bannerContainer.insertBefore(nextVisibleBanner, currentVisibleBanner);

    // Bit of a hacky way of forcing the browser to acknowledge the transition change
    setTimeout(() => {
      this.bannerContainer.style.transition = "left 0.5s ease";
      this.bannerContainer.style.left = `-0px`;
    }, 0);

    this.bannerContainer.addEventListener(
      "transitionend",
      this.handleTransitionEnd
    );
  };

  handleTransitionEnd = () => {
    this.stopActions = false;
    this.startTime = Date.now();
    this.bannerContainer.removeEventListener(
      "transitionend",
      this.handleTransitionEnd
    );
  };

  pauseBannerShift = () => {
    clearTimeout(this.bannerInterval);
    this.remainingTime = 4000 - (Date.now() - this.startTime);
  };

  shiftBannerLeftClick = () => {
    analytics.track("Promo Banner Click Left");
    clearInterval(this.bannerInterval);
    this.initiateBannerScroll();

    this.shiftBannerRight();
  };

  shiftBannerRightClick = () => {
    analytics.track("Promo Banner Click Right");
    clearInterval(this.bannerInterval);
    this.initiateBannerScroll();

    this.shiftBannerLeft();
  };

  checkBannerWidthOnResize = () => {
    if (window.innerWidth < 380) {
      this.bannerWidth = window.innerWidth;
    } else {
      this.bannerWidth =
        this.bannerContainer.querySelector(".inner-banner").offsetWidth;
    }
  };

  resumeBannerShift = () => {
    // We check this.stopActions here because we don't want the banner to suddenly scroll if the user hovers / unhovers mid banner shift
    if (this.remainingTime <= 0 || this.stopActions) {
      this.initiateBannerScroll();
    } else {
      this.bannerInterval = setTimeout(() => {
        this.shiftBannerLeft();
        this.initiateBannerScroll();
      }, this.remainingTime);
    }
  };
}

const bannerContainer = document.getElementById("banner-viewport");
if (bannerContainer) {
  const bannerSlider = new BannerSlider(bannerContainer);
  bannerSlider.init();
}

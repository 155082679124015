<template>
  <button
    href="#"
    :class="buttonClasses"
    @click.prevent.stop="modalOpen = true">
    {{ t("quick-sample-modal.button-text") }}
  </button>
  <teleport to="#end-of-body">
    <quick-sample-modal
      :open="modalOpen"
      @closed="modalOpen = false" />
  </teleport>
</template>

<script>
import QuickSampleModal from "@/app/javascript/components/shared/QuickSampleModal.vue";

export default {
  name: "QuickSampleButton",
  components: {
    QuickSampleModal
  },
  inject: {
    pdpPage: {
      default: false
    }
  },
  data() {
    return {
      modalOpen: false
    };
  },
  computed: {
    buttonClasses() {
      if (this.pdpPage) {
        return "btn btn-secondary btn-large mb-4 inline-with-cta";
      }

      return "w-full bg-paper-4 hover:bg-paper-5 text-ink text-center h-7 underline";
    }
  },
  mounted() {
    this.modalOpen = true;

    window.addEventListener("showQuickSampleModal", () => {
      this.modalOpen = true;
    });
  }
};
</script>

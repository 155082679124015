import { createApp } from "vue";

import I18n from "../plugins/i18n";
import ComparisonChart from "../components/listings/ComparisonChart.vue";
import { ready } from "../utils/listeners";

window.setupComparisonChart = () => {
  const nodes = document.querySelectorAll(".comparison-chart-app");
  if (!nodes.length) return;

  nodes.forEach(node => {
    const props = JSON.parse(node.dataset.props);
    const translations = JSON.parse(node.dataset.translations);

    const app = createApp(ComparisonChart, props);

    app.use(I18n, translations);

    app.mount(node);
  });
};

ready(window.setupComparisonChart);

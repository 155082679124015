import { on } from "../utils/listeners";
import api from "../api";

on("click", "#js-photobook-add-cta", (el, e) => {
  e.preventDefault();

  const { quickAddIds } = el.dataset;

  api
    .post(
      e.target.href,
      {},
      { params: { quick_add_ids: quickAddIds ? quickAddIds.split(",") : null } }
    )
    .then(({ data }) => {
      const { path } = data;
      window.location.href = path;
    });
});

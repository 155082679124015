import { createApp } from "vue";
import QuickAddItems from "@/app/javascript/components/listings/QuickAddItems.vue";
import I18n from "../plugins/i18n";
import { on, ready } from "../utils/listeners";

window.setupQuickAdd = () => {
  const node = document.querySelector("#quick-add-app");
  if (!node) return;

  const translations = JSON.parse(node.dataset.translations);
  const props = JSON.parse(node.dataset.props);
  const app = createApp(QuickAddItems, props);

  const customiseButton = document.querySelector(".js-listing-customise-cta");

  const changeCustomiseUrlQuickAdd = quickAddIds => {
    if (customiseButton && customiseButton.href) {
      const customiseUrl = new URL(customiseButton.href);
      quickAddIds.forEach(id => {
        customiseUrl.searchParams.append("quick_add_ids[]", id);
      });
      customiseButton.href = customiseUrl.toString();
    }
  };

  // For adding a quick add item from a stock item PDP
  const changeQuickAddField = quickAddIds => {
    const quickAddForm = document.querySelector(
      "#js-stock-item-add-to-cart-form"
    );

    if (quickAddForm) {
      quickAddIds.forEach(id => {
        const input = document.createElement("input");

        input.setAttribute("type", "hidden");
        input.setAttribute("name", "quick_add_ids[]");
        input.setAttribute("value", id);

        quickAddForm.appendChild(input);
      });
    }
  };

  on("click", ".js-listing-customise-cta", () => {
    const checkboxes = document.querySelectorAll(".quick-add-checkboxes");
    const quickAddIds = [];

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        quickAddIds.push(checkbox.dataset.quickAddId);
      }
    });

    const quickAddItemAdded = Array.from(checkboxes).some(
      checkbox => checkbox.checked
    );
    if (quickAddItemAdded) {
      analytics.track("PDP Quick Upsell Added to Cart");
      changeCustomiseUrlQuickAdd(quickAddIds);
      changeQuickAddField(quickAddIds);
    }
  });

  const setQuickAddIds = () => {
    const checkboxes = document.querySelectorAll(".quick-add-checkboxes");
    const quickAddIds = [];

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        quickAddIds.push(checkbox.dataset.quickAddId);
      }
    });

    if (customiseButton) {
      if (quickAddIds.length) {
        customiseButton.setAttribute(
          "data-quick-add-ids",
          quickAddIds.join(",")
        );
      } else {
        customiseButton.removeAttribute("data-quick-add-ids");
      }
    }
  };

  window.addEventListener("emitUpsellId", setQuickAddIds);

  app.use(I18n, translations);
  app.mount(node);
};

ready(window.setupQuickAdd);

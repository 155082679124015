<template>
  <div
    :class="
      quickAddItemsLength > 1 ? 'quick-add-item-container' : 'inline-with-cta'
    ">
    <div class="w-4/5 px-4">
      <div class="flex items-start py-3">
        <input
          id="quick-add-checkbox"
          name="quick-add-pdp"
          :data-quick-add-id="item.id"
          type="checkbox"
          class="mr-4 mt-1 quick-add-checkboxes"
          autocomplete="off"
          @change="handleCheckboxChange(item.id)" />
        <div class="flex flex-col">
          <p>{{ item.name }}</p>
          <div
            v-if="item.price.has_discount"
            class="flex gap-2 flex-no-wrap items-center my-1">
            <span class="mr-2">
              <strong>(+{{ item.price.actual }})</strong>
            </span>
            <span
              class="typeset-8 p-1 strikethrough bg-sunflower flex-no-shrink">
              +{{ item.price.base }}
            </span>
          </div>
          <span v-else>(+{{ item.price.actual }})</span>
          <a
            href="#"
            class="underline"
            @click.prevent="setActiveQuickAddItem(item)">
            {{ t("stock-items.perfect-partners.see-more") }}
          </a>
        </div>
        <upsell-modal
          v-if="activeQuickAddItem"
          :upsell="activeQuickAddItem"
          :close-modal="closeModal"
          perfect-partners />
      </div>
    </div>
    <div class="bg-paper-4 flex items-center">
      <img
        height="69"
        width="69"
        :src="item.carousel_images[0].thumb"
        class="cursor-pointer"
        @click.prevent="setActiveQuickAddItem(item)" />
    </div>
    <div
      v-if="quickAddItemsLength > 1"
      class="gradient-overlay hidden md:block"></div>
  </div>
</template>

<script>
import UpsellModal from "@/app/javascript/components/addOns/extras/multiple/UpsellModal.vue";

export default {
  name: "QuickAddItem",
  components: {
    UpsellModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    quickAddItemsLength: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      activeQuickAddItem: null
    };
  },
  mounted() {
    if (this.quickAddItemsLength > 1) {
      const el = this.$el;
      const observer = new IntersectionObserver(
        observations => {
          observations.forEach(observation => {
            if (observation.isIntersecting) {
              this.$emit("update:modelValue", this.index);
            }
          });
        },
        {
          root: document.querySelector(".js-quick-add-container"),
          threshold: 0.6
        }
      );

      observer.observe(el);
    }
  },
  methods: {
    setActiveQuickAddItem(item) {
      this.activeQuickAddItem = item;
    },
    closeModal() {
      this.activeQuickAddItem = null;
    },
    handleCheckboxChange(id) {
      window.dispatchEvent(
        new CustomEvent("emitUpsellId", {
          detail: id
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.quick-add-item-container {
  width: 85.4%;
  flex-shrink: 0;
  min-width: 292px;

  @media (min-width: $screen-md-min) {
    width: 69.4%;
  }
}

.gradient-overlay {
  position: absolute;
  top: 32px;
  right: -15px;
  bottom: 0;
  width: 100px;
  background: linear-gradient(
    to right,
    rgba(250, 247, 240, 0) 0%,
    rgba(250, 247, 240, 1) 50%,
    rgba(250, 247, 240, 1) 100%
  );
  pointer-events: none;
}
</style>

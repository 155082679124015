import { on } from "../utils/listeners";

on("click", ".pw-slides-next, .pw-slides-prev", (el, e) => {
  e.preventDefault();

  const thisSlide = el.closest(".pw-slide");
  const allSlides = el.closest(".pw-slides").querySelectorAll(".pw-slide");

  thisSlide.classList.remove("active");

  const index = [].indexOf.call(allSlides, thisSlide);

  let newIndex = el.classList.contains("pw-slides-next")
    ? index + 1
    : index - 1;

  if (newIndex === -1) newIndex = allSlides.length - 1;
  if (newIndex === allSlides.length) newIndex = 0;

  allSlides[newIndex].classList.add("active");
});

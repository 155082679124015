import Cookies from "js-cookie";
import { on, ready } from "../utils/listeners";

ready(() => {
  const orderBanner = document.querySelector("#order-details-banner");
  const orderBannerClose = document.querySelector(".js-dismiss-order-banner");
  if (orderBanner) {
    analytics.track("Order banner shown", {
      orderId: orderBannerClose.dataset.orderId,
      orderStatus: orderBannerClose.dataset.orderStatus
    });
  }

  on("click", ".js-dismiss-order-banner", (el, e) => {
    e.preventDefault();
    analytics.track("Order banner dismissed");
    Cookies.remove("dismissedOrderDetails");
    Cookies.set(
      "dismissedOrderDetails",
      `${el.dataset.orderId}_${el.dataset.orderStatus}`,
      {
        expires: 3650
      }
    );
  });
});

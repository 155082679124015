<template>
  <div>
    <overlay v-if="loading" />
    <div class="radio-button-section mb-6">
      <radio-input
        class="mb-3"
        :current-value="selectedOption"
        value="leaveBlank"
        input-id="leaveBlank"
        :label="t('product.quick-add-greeting-card.leave-blank')"
        label-class="cursor-pointer typeset-7 gap-x-3"
        @input="selectedOption = 'leaveBlank'" />
      <radio-input
        :current-value="selectedOption"
        value="customise"
        input-id="customise"
        :label="t('product.quick-add-greeting-card.add-message')"
        label-class="cursor-pointer typeset-7 gap-x-3"
        @input="selectedOption = 'customise'" />
    </div>
    <div class="product-page-cta-container">
      <div class="mb-4 md:w-4/5 flex">
        <a
          class="btn btn-large btn-primary btn-block js-listing-customise-cta"
          :class="
            selectedOption === 'customise' ? 'ab-077-customise' : 'ab-077-blank'
          "
          :data-method="dataMethod"
          :href="buttonPath"
          @click="handleClick">
          {{ buttonText }}
        </a>
        <div
          class="favourite-product-container js-favourite-container bg-paper-1 cursor-pointer flex-no-shrink flex items-center justify-center text-paper-6 ml-3"
          :data-id="productId"
          :data-analytics-id="`${productId}`"
          data-cy="favourite-heart"
          data-type="Product">
          <heart-filled-svg />
          <heart-svg />
        </div>
      </div>
      <div class="mb-4 inline-with-cta hybrid-show compact-show">
        <button
          class="btn btn-secondary btn-large btn-block js-hybrid-full-details">
          {{ t("hybrid-pdp.cta-text") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioInput from "@/app/javascript/components/shared/RadioInput.vue";
import HeartFilledSvg from "@/app/javascript/components/shared/HeartFilledSvg.vue";
import HeartSvg from "@/app/javascript/components/shared/HeartSvg.vue";
import Overlay from "@/app/javascript/components/shared/Overlay.vue";

export default {
  name: "QuickAddGreetingCard",

  components: {
    RadioInput,
    HeartFilledSvg,
    HeartSvg,
    Overlay
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    customisePath: {
      type: String,
      required: true
    },
    quickAddPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedOption:
        sessionStorage.getItem(window.location.href) || "leaveBlank",
      loading: false,
      quickAddIds: []
    };
  },
  computed: {
    buttonText() {
      return this.selectedOption === "customise"
        ? this.t("product.quick-add-greeting-card.cta-add-message")
        : this.t("product.quick-add-greeting-card.cta-leave-blank");
    },
    baseButtonPath() {
      return this.selectedOption === "customise"
        ? this.customisePath
        : this.quickAddPath;
    },
    buttonPath() {
      if (this.quickAddIds.length) {
        const url = new URL(this.baseButtonPath, window.location.origin);
        this.quickAddIds.forEach(id => {
          url.searchParams.append("quick_add_ids[]", id);
        });
        return url.toString();
      }
      return this.baseButtonPath;
    },
    dataMethod() {
      return this.selectedOption === "customise" ? "get" : "post";
    }
  },
  watch: {
    selectedOption(newVal) {
      sessionStorage.setItem(window.location.href, newVal);
    }
  },
  mounted() {
    window.addFavourites();
    window.addEventListener("emitUpsellId", this.setQuickAddIds);
  },
  methods: {
    setQuickAddIds(e) {
      const id = e.detail;
      const index = this.quickAddIds.indexOf(id);
      if (index > -1) {
        this.quickAddIds.splice(index, 1);
      } else {
        this.quickAddIds.push(id);
      }
    },
    handleClick() {
      if (this.quickAddId) {
        analytics.track("PDP Quick Upsell Added to Cart");
        this.loading = true;
      }
      this.loading = true;
    }
  }
};
</script>

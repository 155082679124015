import { enter } from "el-transition";
import { ready } from "../utils/listeners";

const setupPopUp = () => {
  const popup = document.querySelector(".marketing-pop-up-container");
  if (!popup) return;

  enter(popup).then(() => {
    analytics.track("Email Popup Shown");
  });
};

ready(() => {
  setTimeout(() => {
    setupPopUp();
  }, 4000);
});

<template>
  <div
    class="pointer-events-auto relative expanding-pill cursor-pointer"
    @click="handleClick">
    <div
      ref="expandingPillText"
      :class="{ expanded: expanded }"
      class="expanding-pill-text bg-white p-3 flex items-center justify-left">
      <span>
        <strong><slot></slot></strong>
      </span>
    </div>
    <div
      class="expanding-pill-icon absolute top-0 bottom-0 right-0 bg-white br-circle p-3 flex items-center justify-center h-full w-full">
      <svg-icon
        :width="iconWidth"
        :height="iconHeight"
        :name="svgIconName" />
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";

export default {
  name: "ExpandingPill",
  components: {
    SvgIcon
  },
  props: {
    svgIconName: {
      type: String,
      required: true
    },
    responsiveMode: {
      type: Boolean,
      required: true
    },
    iconWidth: {
      type: Number,
      default: 20
    },
    iconHeight: {
      type: Number,
      default: 20
    }
  },
  emits: ["click"],
  data() {
    return {
      expanded: true
    };
  },
  watch: {
    expanded(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.setTimeOutToCollapse();
      }
    }
  },
  mounted() {
    this.setTimeOutToCollapse();
  },
  methods: {
    setTimeOutToCollapse() {
      setTimeout(() => {
        this.expanded = false;
      }, 3000);
    },
    handleClick() {
      if (this.responsiveMode) {
        if (this.expanded) {
          this.$emit("click");
        } else {
          this.expanded = true;
        }
      } else {
        this.$emit("click");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.expanding-pill-icon,
.expanding-pill-text {
  height: 48px;
}

.expanding-pill-icon {
  width: 48px;
}

.expanding-pill-text {
  transform-origin: 100% 100%;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.1s ease-in-out;
  transform: scaleX(0);
  opacity: 0;
  padding-right: 24px;
  padding-left: 16px;
  border-radius: 30px 0 0 30px;
  position: relative;
  left: -24px;

  span {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
}

.expanding-pill {
  @media (min-width: $screen-lg-min) {
    &:hover {
      .expanding-pill-text {
        box-shadow: 0 8px 24px 0 rgba(0, 12, 27, 0.16);
        opacity: 1;
        transform: scaleX(1);
        span {
          opacity: 1;
        }
      }
    }
  }
  .expanded {
    &.expanding-pill-text {
      box-shadow: 0 8px 24px 0 rgba(0, 12, 27, 0.16);
      opacity: 1;
      transform: scaleX(1);
      span {
        opacity: 1;
      }
    }
  }
}
</style>

<template>
  <a
    :href="job.application_url"
    class="bg-paper focus:bg-paper-2 hover:bg-paper-2 cursor-pointer pr-5 pl-4 py-4 flex items-center justify-between mb-4">
    <p class="typeset-7">{{ job.title }}</p>
    <svg-icon name="rightarrow" />
  </a>
</template>

<script>
import SvgIcon from "../shared/SvgIcon.vue";

export default {
  name: "Job",
  components: {
    SvgIcon
  },
  props: {
    job: {
      type: Object,
      required: true
    }
  }
};
</script>

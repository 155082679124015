import { on } from "../utils/listeners";

const readMoreElements = document.querySelectorAll(".js-read-more");
const categoryDescription = document.querySelector(".category-description");

if (categoryDescription) {
  const shouldShowReadMore =
    categoryDescription.getBoundingClientRect().height > 62.671875;

  if (shouldShowReadMore) {
    readMoreElements[0].classList.add("show-link");
  }

  on("click", ".trigger", (el, e) => {
    e.preventDefault();

    if (el.textContent === "Read more") {
      el.textContent = "Read less";
    } else {
      el.textContent = "Read more";
    }

    readMoreElements.forEach(elm => {
      if (!elm.classList.contains("read-more-expanded")) {
        elm.classList.add("read-more-expanded");
      } else {
        elm.classList.remove("read-more-expanded");
      }
    });
  });
}

<template>
  <svg
    class="icon-faveheart-filled"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 2.17446C11.1001 0.82838 12.75 0 14.5 0C17.6001 0 20 2.48489 20 5.6948C20 9.62914 16.6 12.7875 11.4502 17.6536L10 19L8.54981 17.6536C3.3999 12.7875 0 9.62919 0 5.6948C0 2.48489 2.3999 0 5.5 0C7.25 0 8.89986 0.82838 10 2.17446Z"
      fill="currentColor" />
  </svg>
</template>

import Cookies from "js-cookie";
import { on, ready } from "../utils/listeners";
import api from "../api";

ready(() => {
  const banner = document.querySelector("#change-store-bar");
  if (banner) {
    analytics.track("Store Redirect Banner Shown", {
      store:
        window.countryCode === ""
          ? "GB"
          : window.countryCode.replace("/", "").toUpperCase()
    });
  }

  on("click", ".js-switch-store", el => {
    Cookies.set("preferredStore", el.dataset.storeCode, {
      expires: 365
    });
  });

  on("click", "#dismiss-change-store-bar", (el, e) => {
    e.preventDefault();
    api.get("/confirm_store");
  });
});

import { createApp } from "vue";

import I18n from "../plugins/i18n";
import FavouritesDrawer from "../pages/FavouritesDrawer.vue";
import { on, ready } from "../utils/listeners";

ready(() => {
  on("click", ".js-favourites-trigger", (_el, e) => {
    e.preventDefault();
    window.dispatchEvent(new CustomEvent("openFavouritesDrawer"));
  });

  const favouritesDrawerNode = document.getElementById("favourites-drawer-app");
  if (!favouritesDrawerNode) return;

  const translations = JSON.parse(favouritesDrawerNode.dataset.translations);

  const app = createApp(FavouritesDrawer);
  app.use(I18n, translations);
  app.mount(favouritesDrawerNode);
});

import { createApp } from "vue";
import VueTippy from "vue-tippy";
import store from "../store/userNav";

import I18n from "../plugins/i18n";
import MiniFavourites from "../pages/MiniFavourites.vue";

const mountDesktopApp = async (node, translations) => {
  const app = createApp(MiniFavourites);
  app.use(store);
  app.use(VueTippy);
  app.use(I18n, translations);
  app.mount(node);
};

document.addEventListener("DOMContentLoaded", () => {
  const miniFavouritesNode = document.getElementById("mini-favourites-app");
  if (!miniFavouritesNode) return;

  const translations = JSON.parse(miniFavouritesNode.dataset.translations);

  mountDesktopApp(miniFavouritesNode, translations);
});

<template>
  <div>
    <div class="relative">
      <div
        v-if="isOpen"
        class="fixed pin z-4"></div>
      <drawer-transition>
        <div
          v-if="isOpen"
          :id="`filters-drawer-${formattedTitle}`"
          class="fixed top-0 right-0 bottom-0 filters-drawer bg-paper-1 flex flex-col drawer-right">
          <div
            class="overflow-auto drawer-height md:p-4 justify-betweeen items-center cursor-pointer filter-checkbox-container">
            <div class="flex justify-between pr-5">
              <div
                class="p-5 flex cursor-pointer justify-center items-center"
                @click="$emit('closeActiveDrawer')">
                <svg-icon
                  class="mr-4 items-center"
                  name="leftarrow-small-thin"
                  height="12px"
                  width="12px" />
                <p
                  class="underline cursor-pointer self-center"
                  data-cy="back-to-all-filters">
                  All filters
                </p>
              </div>
              <div
                class="cursor-pointer self-center"
                data-cy="close-filter-panel"
                @click="closeDrawers">
                <svg-icon
                  height="24px"
                  width="24px"
                  name="close-display" />
              </div>
            </div>
            <div class="mb-2 pb-5 md:pb-0">
              <h3
                data-cy="filter-title"
                class="typeset-3 px-5 pt-6 pb-4 mb-4">
                {{ activeFilter.title }}
              </h3>
              <div
                v-for="(option, index) in sortedOptions"
                :key="option.name"
                class="flex px-5 py-4 justify-betweeen items-center cursor-pointer filter-checkbox-container"
                @click="triggerHandleChange(option)">
                <checkbox-input
                  v-model:checked="option.active"
                  :input-id="`${activeFilter.key}-${index}`"
                  class="mr-4"
                  filters-checkbox />
                <h5 class="cursor-pointer mb-2">
                  {{ option.name }}
                  <span
                    :data-cy="`choose-${option.name
                      .replaceAll(' ', '-')
                      .toLowerCase()}`"
                    class="ml-3 text-ink-3 cursor-pointer">
                    ({{ option.count }})
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </drawer-transition>
    </div>
  </div>
</template>

<script>
import CheckboxInput from "../components/shared/CheckboxInput.vue";
import SvgIcon from "../components/shared/SvgIcon.vue";
import DrawerTransition from "../components/transitions/DrawerTransition.vue";

export default {
  name: "ActiveFilterDrawer",
  components: {
    SvgIcon,
    CheckboxInput,
    DrawerTransition
  },
  props: {
    activeFilter: {
      type: Object,
      required: false,
      default: () => {}
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ["closeDrawers", "clearFilters", "handleChange", "closeActiveDrawer"],
  computed: {
    sortedOptions() {
      return [...this.activeFilter.options].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    formattedTitle() {
      return this.activeFilter?.title.replaceAll(" ", "-").toLowerCase();
    }
  },
  methods: {
    closeDrawers() {
      this.$emit("closeDrawers");
    },
    triggerHandleChange(option) {
      if (!option.active) {
        analytics.track("Clicked Filter", {
          name: this.activeFilter.title,
          value: option.name
        });
      }
      option.active = !option.active;
      this.$emit("handleChange");
    }
  }
};
</script>

<template>
  <div class="flex flex-col items-center md:mx-auto">
    <h2
      v-if="title"
      class="typeset-2 mb-6 md:mb-7 text-center">
      {{ title }}
    </h2>
    <div
      class="w-full segmented-control-container flex flex-col flex-1"
      :class="containerClasses"
      :style="heightStyles">
      <div
        v-if="options.length > 1"
        :class="headerWidthClasses"
        class="flex items-center justify-center text-center pb-4 md:pb-0 cursor-pointer mx-auto">
        <h3
          v-for="(option, index) in options"
          :key="index"
          class="md:p-5 typeset-4"
          :class="[textColour(index)]"
          :style="tabWidth"
          @click="selectTab(index)">
          {{ option }}
        </h3>
      </div>
      <div
        v-if="options.length > 1"
        :class="headerWidthClasses"
        class="tab-track h-2 relative mb-6 md:mb-6 mx-auto">
        <div
          ref="tabIndicator"
          :style="tabWidth"
          class="tab-indicator bg-paper-6 absolute top-0 left-0 bottom-0"></div>
      </div>
      <div class="md:mt-3 min-height-0 h-full flex-1 relative">
        <fade-transition
          v-if="useFadeTransition"
          cross-fade>
          <slot></slot>
        </fade-transition>
        <slot v-else></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
import FadeTransition from "@/app/javascript/components/transitions/FadeTransition.vue";

export default {
  name: "SegmentedControlWithContent",
  components: {
    FadeTransition
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    },
    preventSwipeNavigation: {
      type: Boolean,
      default: false
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    containerClasses: {
      type: Array,
      default: () => ["bg-paper", "p-6", "md:px-8"]
    },
    headerWidthClasses: {
      type: Array,
      default: () => ["w-full"]
    },
    useFadeTransition: {
      type: Boolean,
      default: false
    },
    applyCustomHeight: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:activeIndex"],
  computed: {
    textColour() {
      return index => (index === this.activeIndex ? null : "text-ink-3");
    },
    tabWidth() {
      return {
        width: `${100 / this.options.length}%`
      };
    },
    heightStyles() {
      if (this.applyCustomHeight) {
        return {
          maxHeight: "1000px",
          height: "673.5px",
          minHeight: "673.5px"
        };
      }
      return {};
    }
  },
  watch: {
    activeIndex() {
      this.positionTabIndicator();
    }
  },
  mounted() {
    this.positionTabIndicator();

    if (!this.preventSwipeNavigation) {
      const swipeableEl = document.querySelector(
        ".segmented-control-container"
      );
      this.hammer = Hammer(swipeableEl);
      this.hammer.on("swipeleft", () => this.nextPage());
      this.hammer.on("swiperight", () => this.prevPage());
    }
  },
  methods: {
    prevPage() {
      if (this.activeIndex > 0) {
        this.setActiveIndex(this.activeIndex - 1);
      }
    },
    nextPage() {
      if (this.activeIndex < this.options.length - 1) {
        this.setActiveIndex(this.activeIndex + 1);
      }
    },
    selectTab(index) {
      this.setActiveIndex(index);
    },
    setActiveIndex(index) {
      this.$emit("update:activeIndex", index);
    },
    positionTabIndicator() {
      if (!this.$refs.tabIndicator) return;

      const el = this.$refs.tabIndicator;
      el.style.transform = `translateX(${100 * this.activeIndex}%)`;
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-track {
  background-color: rgba($color: #a99a78, $alpha: 0.24);
}

.tab-indicator {
  transition: transform 0.2s ease-in-out;
}

.min-height-0 {
  min-height: 0;
}
</style>

import { createApp } from "vue";

import I18n from "../plugins/i18n";
import Careers from "../pages/Careers.vue";

import { ready } from "../utils/listeners";

ready(() => {
  const node = document.getElementById("careers-app");
  if (!node) return;

  const translations = JSON.parse(node.dataset.translations);

  const app = createApp(Careers);

  app.use(I18n, translations);

  app.mount(node);
});

<template>
  <div>
    <div
      class="flex fixed mm-corner-peel cursor-pointer z-3"
      :class="{ hidden: dismiss }">
      <div
        class="h-6 flex items-center px-3 bg-white border-b border-t border-l border-paper-4"
        @click="openMentionMeModal">
        <p>
          {{ mentionMeData.defaultCallToAction }}
        </p>
      </div>
      <div
        class="bg-paper-4 h-6 w-6 flex justify-center items-center"
        @click="dismissCornerPeel">
        <svg-icon
          height="10"
          width="10"
          name="cross" />
      </div>
    </div>
    <modal
      :open="open"
      class="mention-me-modal"
      modal-close-class="modal-close-light"
      ground-close
      @closed="closeMentionMeModal">
      <template #body>
        <iframe
          id="preview-iframe"
          ref="iframe"
          class="shadow-2 w-full h-full block mention-me-iframe"
          style="width: 100%; height: 90vh"
          :src="mentionMeData.url"
          frameborder="0"></iframe>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/app/javascript/components/shared/Modal.vue";
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";

export default {
  name: "MentionMe",
  components: {
    Modal,
    SvgIcon
  },
  props: {
    mentionMeData: {
      type: Object,
      required: true
    },
    location: {
      type: String,
      required: true
    },
    isGuestCheckout: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open: false,
      dismiss: false
    };
  },
  computed: {
    mentionMe() {
      return document.querySelector(".js-open-mention-me");
    },
    isAustralia() {
      return window.countryCode === "/au";
    }
  },
  mounted() {
    if (this.isAustralia) {
      this.dismiss = true;
      this.open = false;
    } else {
      this.open = !this.isGuestCheckout;
    }
    if (this.mentionMe) {
      this.mentionMe.addEventListener("click", this.openMentionMeModal);
    }
  },
  beforeUnmount() {
    if (this.mentionMe) {
      this.mentionMe.removeEventListener("click", this.openMentionMeModal);
    }
  },
  methods: {
    dismissCornerPeel() {
      analytics.track("Dismissed Mention Me Corner Peel");
      this.dismiss = true;
    },
    openMentionMeModal() {
      analytics.track("Opened Mention Me Modal");
      this.open = true;
    },
    closeMentionMeModal() {
      analytics.track("Closed Mention Me Modal");
      this.open = false;
    }
  }
};
</script>

<style lang="scss">
.mm-corner-peel {
  right: 16px;
  bottom: 80px;

  @media screen and (min-width: $screen-sm-min) {
    bottom: 78px;
  }
}

.mention-me-iframe {
  @media screen and (min-width: $screen-sm-min) {
    min-width: 960px;
    max-height: 630px;
  }
}

.mention-me-modal .modal-dialog {
  width: auto;
  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
  }
}

.mention-me-modal .modal-dialog .modal-body {
  padding: 0px;
}

.mention-me-modal .modal-dialog .modal-content {
  @media screen and (min-width: $screen-sm-min) {
    min-width: 960px;
    max-height: 630px;
  }
}
</style>

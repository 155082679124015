<template>
  <div
    class="image-carousel-actions absolute pin pointer-events-none flex flex-col justify-start items-end p-4 gap-4">
    <div class="image-zoom-button">
      <expanding-pill
        :responsive-mode="responsiveMode"
        svg-icon-name="zoom"
        @click="zoom">
        {{ t("image-carousel-buttons.zoom") }}
      </expanding-pill>
    </div>
    <expanding-pill
      v-if="showFlickthrough"
      :responsive-mode="responsiveMode"
      svg-icon-name="flickthrough-icon"
      @click="scrollToFlickthrough">
      {{ t("image-carousel-buttons.flickthrough") }}
    </expanding-pill>
    <expanding-pill
      v-if="show3d && !responsiveMode"
      :responsive-mode="responsiveMode"
      :icon-width="24"
      :icon-height="24"
      svg-icon-name="three-d"
      @click="show3dPreview">
      {{ t("image-carousel-buttons.three-d") }}
    </expanding-pill>
  </div>
</template>

<script>
import ExpandingPill from "./ExpandingPill.vue";

export default {
  name: "ImageCarouselActions",
  components: { ExpandingPill },
  props: {
    showFlickthrough: {
      type: Boolean,
      default: false
    },
    responsiveMode: {
      type: Boolean,
      required: true
    },
    show3d: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    zoom() {
      window.dispatchEvent(new Event("clickedImage"));
      analytics.track("Clicked Zoom");
    },
    scrollToFlickthrough() {
      window.dispatchEvent(new Event("clickedScrollToFlickthrough"));
      analytics.track("Clicked Look Inside");
    },
    show3dPreview() {
      window.dispatchEvent(new Event("show3dPreview"));
      analytics.track("Clicked Show 3D Image");
    }
  }
};
</script>

<style lang="scss">
.is-video {
  .image-zoom-button {
    display: none;
  }
}

.is-flickthrough-carousel-item {
  .image-carousel-actions {
    display: none;
  }
}
</style>

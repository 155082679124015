<template>
  <segmented-control-with-content
    id="comparison-chart"
    v-model:active-index="activeIndex"
    class="py-7 md:px-9"
    bg-colour="bg-paper"
    :title="comparisonChartTitle"
    :options="segmentedControlOptions"
    :apply-custom-height="false"
    use-fade-transition>
    <div
      :key="activeIndex"
      class="flex items-start justify-between flex-col md:flex-row gap-7 w-full">
      <div class="md:w-1/2">
        <div
          v-for="(info, index) in activeProduct.info"
          :key="index">
          <p
            class="typeset-6"
            :class="info.subheading ? 'mb-3' : 'mb-6'">
            {{ info.heading }}
          </p>
          <p
            v-if="info.subheading"
            class="typeset-7"
            :class="isLastItem(index) ? 'mb-4' : 'mb-6'"
            v-html="info.subheading"></p>
        </div>
        <a
          v-if="activeProduct.url"
          :href="activeProduct.url"
          class="underline"
          @click="triggerAnalyticsEvent(activeProduct.name)">
          {{
            t("product.comparison-chart.link-text", {
              product: activeProduct.pluralised_name
            })
          }}
        </a>
      </div>
      <div class="md:w-1/2 w-full md:pb-5">
        <img
          height="254"
          width="452"
          :src="activeProduct.image"
          class="aspect-ratio-16x9 img-responsive w-full" />
      </div>
    </div>
  </segmented-control-with-content>
</template>

<script>
import SegmentedControlWithContent from "@/app/javascript/components/shared/SegmentedControlWithContent.vue";

export default {
  name: "ComparisonChart",
  components: { SegmentedControlWithContent },
  props: {
    productTypeName: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      default: () => []
    },
    comparisonGroup: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      key: 0,
      activeIndex: 0
    };
  },
  computed: {
    segmentedControlOptions() {
      return this.rearrangedProducts.map(product => product.pluralised_name);
    },
    rearrangedProducts() {
      if (this.comparisonGroup === "photo_book") {
        return this.products;
      }

      const currentProduct = this.products[this.findProductIndex];
      const otherProducts = this.products.filter(p => p !== currentProduct);

      return [currentProduct, ...otherProducts];
    },
    comparisonChartTitle() {
      if (this.comparisonGroup === "journal") {
        return this.t("product.comparison-chart.journal-title");
      }
      if (this.comparisonGroup === "photo_book") {
        return this.t("product.comparison-chart.photo-book-title");
      }
      return this.t("product.comparison-chart.diary-title");
    },
    activeProduct() {
      return this.rearrangedProducts[this.activeIndex];
    },
    findProductIndex() {
      return this.products.findIndex(product =>
        product.ids.includes(this.productTypeName.id)
      );
    },
    isLastItem() {
      return index => index === this.activeProduct.info.length - 1;
    }
  },
  mounted() {
    const activeIndex = this.rearrangedProducts.findIndex(product =>
      product.ids.includes(this.productTypeName.id)
    );
    this.activeIndex = activeIndex;
  },
  methods: {
    triggerAnalyticsEvent(name) {
      analytics.track("Clicked Comparison Chart Category", {
        value: name
      });
    }
  }
};
</script>

<template>
  <div
    id="available-openings"
    class="p-4 sm:py-7 bg-paper-3">
    <div class="px-4 py-6 sm:px-6 -m-4 sm:m-0 bg-paper-1 md:mx-auto md:w-6/12">
      <h2 class="typeset-2 mb-4">{{ t("careers.title") }}</h2>
      <p class="typeset-7 mb-4">{{ t("careers.subtitle") }}</p>

      <div
        class="relative"
        :class="{ 'loader-height': loading }">
        <overlay
          v-if="loading"
          absolutely-positioned />
        <p
          v-else-if="!Object.keys(departments).length"
          class="typeset-7 mb-0">
          {{ t("careers.no-results") }}
        </p>
        <div v-else>
          <department
            v-for="(locations, name) in departments"
            :key="name"
            :name="name"
            :locations="locations" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";

import Department from "../components/careers/Department.vue";
import Overlay from "../components/shared/Overlay.vue";

export default {
  name: "Careers",
  components: {
    Department,
    Overlay
  },
  data() {
    return {
      loading: true,
      departments: {}
    };
  },
  async mounted() {
    const res = await api.get("/jobs");
    this.departments = res.data.departments;
    this.loading = false;
  }
};
</script>

<style scoped>
.loader-height {
  min-height: 80px;
}
</style>

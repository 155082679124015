import Flickity from "flickity";
import { on } from "../utils/listeners";
import api from "../api";

function setUpFoldlisteners() {
  on("click", ".js-fold-load-more", (el, e) => {
    e.preventDefault();
    api
      .get(el.href)
      .then(response => {
        document.querySelector(".js-fold-load-more-container").remove();
        document
          .querySelector("#fold-articles-container")
          .insertAdjacentHTML("beforeend", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  });

  on("click", ".fold-homepage-scroller", () => {
    document.querySelector("#scroll-to-target").scrollIntoView({
      behavior: "smooth"
    });
  });

  // Product page thumbnail images
  const carousels = document.querySelectorAll(".fold-carousel");
  const options = {
    pageDots: true,
    prevNextButtons: true,
    imagesLoaded: true,
    arrowShape:
      "M2.896 52.164A4.716 4.716 0 0 1 .6 48a4.716 4.716 0 0 1 2.292-4.164l41.82-42.464a4.572 4.572 0 0 1 6.536 0c1.8 1.832 1.8 4.804 0 6.636L11.86 48l39.388 39.992c1.8 1.832 1.8 4.8 0 6.636a4.572 4.572 0 0 1-6.536 0L2.896 52.16v.004z",

    // These events work around ios 11.3+ bug
    // https://github.com/metafizzy/flickity/issues/740
    on: {
      dragStart: () => {
        document.ontouchmove = e => {
          e.preventDefault();
        };
      },
      dragEnd: () => {
        document.ontouchmove = () => true;
      }
    }
  };

  if (carousels.length) {
    carousels.forEach(carousel => {
      // annoyingly we have to use the new syntax but don't need the variable.
      // eslint-disable-next-line no-unused-vars
      const flkty = new Flickity(carousel, options);
    });
  }
}

document.addEventListener("DOMContentLoaded", setUpFoldlisteners);

import { createApp } from "vue";

import I18n from "../plugins/i18n";
import Filters from "../pages/FiltersDrawer.vue";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("filters-drawer-app");
  if (!node) return;

  const dataNode = document.getElementById("filters-data");

  const props = JSON.parse(dataNode.dataset.props);
  const translations = JSON.parse(dataNode.dataset.translations);

  const app = createApp(Filters, props);

  app.use(I18n, translations);

  app.mount(node);
});

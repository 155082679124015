<template>
  <div
    class="relative"
    :class="{ 'quick-add-carousel': quickAddItems?.length > 1 }">
    <div
      class="flex justify-between items-center pr-2 md:pr-0"
      :class="quickAddItems?.length > 1 ? 'quick-add-item-controls' : 'w-full'">
      <h3 class="typeset-6 mb-3">
        {{ t("stock-items.perfect-partners.checkbox-title") }}
      </h3>
      <div
        v-if="quickAddItems?.length > 1"
        class="mb-3">
        <div class="hidden md:block">
          <svg-icon
            class="cursor-pointer"
            :class="
              disableLeftArrow
                ? 'text-ink-2 pointer-events-none'
                : 'pointer-events-auto'
            "
            name="leftarrow"
            height="12px"
            width="12px"
            @click="scrollElementIntoView('left')" />
          <svg-icon
            class="ml-3 cursor-pointer"
            :class="
              disableRightArrow
                ? 'text-ink-2 pointer-events-none'
                : 'pointer-events-auto'
            "
            name="rightarrow"
            height="12px"
            width="12px"
            @click="scrollElementIntoView('right')" />
        </div>
        <ul
          v-if="quickAddItems"
          class="list-unstyled flex md:hidden gap-3 justify-center">
          <li
            v-for="(dot, index) in quickAddItems"
            :key="index"
            :class="index === inViewIndex ? 'bg-ink' : 'bg-ink-3'"
            class="h-3 w-3 br-circle transition-background"></li>
        </ul>
      </div>
    </div>

    <div
      class="flex overflow-x-auto gap-x-4 hide-scrollbar snap-x snap-mandatory js-quick-add-container">
      <quick-add-item
        v-for="(item, index) in quickAddItems"
        :key="item.id"
        v-model="inViewIndex"
        :item="item"
        :index="index"
        :quick-add-items-length="quickAddItems.length"
        class="flex item-stretch bg-paper snap-start justify-between" />
      <div
        v-if="quickAddItems?.length > 1"
        class="hidden-quick-add-item"></div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";
import QuickAddItem from "@/app/javascript/components/listings/QuickAddItem.vue";

export default {
  name: "QuickAddItems",
  components: {
    SvgIcon,
    QuickAddItem
  },
  props: {
    quickAddItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      modalOpen: false,
      inViewIndex: 0
    };
  },
  computed: {
    disableLeftArrow() {
      return this.inViewIndex === 0;
    },
    disableRightArrow() {
      return this.inViewIndex === this.quickAddItems.length - 1;
    }
  },
  methods: {
    scrollElementIntoView(direction) {
      if (direction === "left" && this.inViewIndex > 0) {
        this.inViewIndex -= 1;
      } else if (
        direction === "right" &&
        this.inViewIndex < this.quickAddItems.length - 1
      ) {
        this.inViewIndex += 1;
      }
      document
        .querySelectorAll(".quick-add-item-container")
        [this.inViewIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center"
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.quick-add-carousel {
  width: auto;
}

.quick-add-item-controls {
  width: 85.4%;

  @media (min-width: $screen-md-min) {
    width: 69.4%;
  }
}

.hidden-quick-add-item {
  min-width: 85.4%;

  @media (min-width: $screen-md-min) {
    width: 69.4%;
  }
}
</style>

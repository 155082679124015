import { createApp } from "vue";

import ImageCarouselActions from "@/app/javascript/components/shared/ImageCarouselActions.vue";
import VueTippy from "vue-tippy";
import I18n from "../plugins/i18n";
import { ready } from "../utils/listeners";
import responsiveMode from "../utils/responsiveMode";

window.setupImageCarouselActions = () => {
  const node = document.querySelector(".image-carousel-actions-app");
  if (!node) return;

  const props = JSON.parse(node.dataset.props);
  const translations = JSON.parse(node.dataset.translations);

  props.responsiveMode = responsiveMode();

  const app = createApp(ImageCarouselActions, props);

  app.use(I18n, translations);
  app.use(VueTippy);

  app.mount(node);
};

ready(() => {
  window.setupImageCarouselActions();
});

import { on } from "../utils/listeners";

on("click", ".designer-filter-button", el => {
  const { category } = el.dataset;
  const buttons = document.querySelectorAll(".designer-filter-button");
  buttons.forEach(button => {
    button.classList.remove("btn-primary");
    button.classList.add("btn-secondary");
  });

  el.classList.remove("btn-secondary");
  el.classList.add("btn-primary");

  const cards = document.querySelectorAll(".designer-card");
  if (category) {
    const cardsToShow = document.querySelectorAll(
      `.designer-card[data-category="${category}"]`
    );
    cards.forEach(card => {
      card.style.display = "none";
    });
    cardsToShow.forEach(card => {
      card.style.display = "";
    });
  } else {
    cards.forEach(card => {
      card.style.display = "";
    });
  }
});

import { createApp } from "vue";

import VueTippy from "vue-tippy";
import I18n from "../plugins/i18n";
import PerfectPartners from "../components/shared/PerfectPartners.vue";
import { ready } from "../utils/listeners";

window.setupPerfectPartners = () => {
  const node = document.getElementById("perfect-partners");
  if (!node) return;

  const props = JSON.parse(node.dataset.props);
  const translations = JSON.parse(node.dataset.translations);

  const app = createApp(PerfectPartners, props);

  app.use(I18n, translations);
  app.use(VueTippy);

  app.mount(node);
};

ready(() => {
  window.setupPerfectPartners();
});

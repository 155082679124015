import { on, ready } from "../utils/listeners";

ready(() => {
  on("click", ".fold-dropdown-header", (el, e) => {
    e.preventDefault();

    const target = Array.from(el.parentElement.children).filter(item =>
      item.classList.contains("fold-dropdown-body")
    )[0];

    target.style.height = `${target.scrollHeight}px`;
    target.style.height = `${target.scrollHeight}px`;

    if (target.classList.contains("in")) {
      target.classList.remove("in");
      target.style.height = 0;
      el.classList.remove("open");
    } else {
      target.classList.add("in");
      target.style.height = `${target.scrollHeight}px`;
      el.classList.add("open");
    }
  });
});

import { on } from "../utils/listeners";
import api from "../api/index";

on("mouseover", ".product .swatch", el => {
  if (el.classList.contains("active")) return;

  const productImageContainer = el
    .closest(".product")
    .querySelector(".product-image");
  const image = {
    url: el.dataset.imageUrl
  };
  const imageElement = new Image();
  imageElement.src = image.url;
  imageElement.dataset.index = el.dataset.index;
  imageElement.setAttribute(
    "class",
    "absolute pin img-responsive bg-paper hover-img opacity-0"
  );
  productImageContainer.appendChild(imageElement);
  imageElement.onload = () => {
    imageElement.classList.remove("opacity-0");
  };
});

on("mouseout", ".product .swatch", (el, e) => {
  if (el.classList.contains("active")) return;

  const indexToRemove = el.dataset.index;
  const productImageContainer = el
    .closest(".product")
    .querySelector(".product-image");
  const imageToRemove = productImageContainer.querySelector(
    `[data-index="${indexToRemove}"]`
  );
  if (!e.relatedTarget.classList.contains("swatch")) {
    imageToRemove.classList.add("opacity-0");
  }
  setTimeout(() => {
    const allImagesMatchingIndex = productImageContainer.querySelectorAll(
      `[data-index="${indexToRemove}"]`
    );
    allImagesMatchingIndex.forEach(image => {
      image.remove();
    });
  }, 400);
});

on("click", ".product .swatch", async (el, e) => {
  // We replace the entire listing element on change

  const listing = e.target.closest("div").parentNode;
  const parent = listing.parentNode;
  const { slug } = el.dataset;
  const result = await api.get(`/${slug}/listing_variant`);
  const divWrapper = document.createElement("div");

  divWrapper.innerHTML = result.data;

  const replacementListing = divWrapper.firstElementChild;
  const listingImage = replacementListing.querySelector("img");

  listingImage.classList.add("opacity-0");

  listingImage.addEventListener("load", () => {
    listingImage.classList.remove("opacity-0");
  });

  parent.replaceChild(replacementListing, listing);

  window.resetFavourites();
});

import { throttle } from "lodash-es";

document.addEventListener("DOMContentLoaded", () => {
  const gnavElements = document.querySelectorAll(".gnav");
  const mobileNav = document.querySelector(".nav-mobile-header");
  const body = document.querySelector("body");

  const toggleNavClasses = () => {
    gnavElements.forEach(gnav => {
      if (body.classList.contains("fold-homepage")) {
        gnav.classList.toggle("bg-paper-2", window.pageYOffset > 30);
        gnav.classList.toggle("text-white", window.pageYOffset < 30);
        mobileNav.classList.toggle("bg-paper-2", window.pageYOffset > 30);
        mobileNav.classList.toggle("text-white", window.pageYOffset < 30);
        mobileNav.classList.toggle("swap", window.pageYOffset > 30);
      } else if (window.location.pathname.includes("/thefold")) {
        gnav.classList.toggle("fold-swap", window.pageYOffset > 30);
        mobileNav.classList.toggle("swap", window.pageYOffset > 30);
      } else if (gnav && mobileNav) {
        // non fold pages
        gnav.classList.toggle("swap", window.pageYOffset > 30);
        mobileNav.classList.toggle("swap", window.pageYOffset > 30);
      }
    });
  };

  window.addEventListener("scroll", throttle(toggleNavClasses, 100));
});

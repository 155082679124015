<template>
  <div>
    <div class="relative drawer-right">
      <fade-transition>
        <div
          v-if="isOpen"
          class="fixed pin filters-drawer-backdrop"
          @click="closeDrawers"></div>
      </fade-transition>
      <div
        v-if="isLoading && !isOpen"
        class="category-page-container-loading fixed pin">
        <fade-transition>
          <overlay
            is-drawer-overlay
            absolutely-positioned />
        </fade-transition>
      </div>
      <div
        v-if="isLoading && isOpen"
        class="fixed pin filters-drawer-container-loading drawer-right">
        <overlay
          is-drawer-overlay
          absolutely-positioned />
      </div>
      <drawer-transition>
        <div
          v-if="isOpen"
          id="filters-drawer-all-filters"
          class="fixed top-0 right-0 bottom-0 filters-drawer bg-paper-1 flex flex-col drawer-right">
          <div class="pb-5 overflow-auto drawer-height">
            <div class="p-5 flex flex-col mb-0 md:mb-4">
              <div
                class="self-end md:pt-4 md:pr-4"
                @click="isOpen = false">
                <svg-icon
                  class="cursor-pointer"
                  height="24px"
                  width="24px"
                  name="close-display" />
              </div>
              <h3
                data-cy="all-filters-panel-heading"
                class="typeset-3 md:px-4 filters-title">
                All filters
              </h3>
            </div>
            <div
              v-for="filter in filtersForEditing"
              :key="filter.title"
              class="px-5">
              <div
                v-if="filter.options.length"
                class="flex md:px-4 py-6 cursor-pointer filter-arrow-container items-center flex-wrap"
                @click="setActiveFilterTitle(filter.title)">
                <h5
                  :data-cy="filter.title.replaceAll(' ', '-').toLowerCase()"
                  class="mr-auto mb-2">
                  {{ filter.title }}
                </h5>
                <svg-icon
                  class="cursor-pointer filter-arrow relative"
                  height="12px"
                  width="12px"
                  name="rightarrow-small-thin" />
                <div
                  class="basis-full typeset-8 text-ink-4 flex flex-wrap mt-1 pr-6"
                  data-cy="applied-filter">
                  {{ activeOptionsString(filter) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </drawer-transition>
    </div>
    <active-filter-drawer
      :active-filter="activeFilter"
      :is-open="activeFilterDrawerIsOpen"
      @handle-change="handleChange"
      @close-drawers="closeDrawers"
      @close-active-drawer="closeActiveDrawer"
      @clear-filters="clearFilters" />
    <drawer-transition>
      <div
        v-if="isOpen"
        class="filter-button-controls">
        <filter-button-controls
          :clear-filter-count="clearFilterCount"
          :active-filter-title="activeFilterTitle"
          :is-loading="isLoading"
          :active-filter-count="activeFilterCount"
          :active-options-within-filter-count="activeOptionsWithinFilterCount"
          :results-count="resultsCount"
          @close-drawers="closeDrawers"
          @clear-filters="clearFilters" />
      </div>
    </drawer-transition>
  </div>
</template>

<script>
import FadeTransition from "@/app/javascript/components/transitions/FadeTransition.vue";
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";
import DrawerTransition from "@/app/javascript/components/transitions/DrawerTransition.vue";
import ActiveFilterDrawer from "@/app/javascript/pages/ActiveFilterDrawer.vue";
import { cloneDeep } from "lodash-es";
import Overlay from "@/app/javascript/components/shared/Overlay.vue";
import FilterButtonControls from "../components/shared/FilterButtonControls.vue";
import applyFilters from "../modules/applyFilters";

export default {
  name: "FiltersDrawer",
  components: {
    FadeTransition,
    DrawerTransition,
    ActiveFilterDrawer,
    SvgIcon,
    FilterButtonControls,
    Overlay
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      default: null
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      activeFilterTitle: "",
      childFilter: "",
      activeFilterDrawerIsOpen: false,
      filtersForEditing: [],
      isLoading: false,
      resultsCount: 0,
      clearFilterCount: 0
    };
  },
  computed: {
    activeFilter() {
      if (this.activeFilterTitle) {
        return this.filtersForEditing.find(
          filter => filter.title === this.activeFilterTitle
        );
      }
      return null;
    },
    activeOptionsForFilter() {
      return filter => filter.options.filter(option => option.active);
    },
    activeOptionsString() {
      return filter =>
        this.activeOptionsForFilter(filter)
          .map(opt => opt.name)
          .join(", ");
    },
    activeOptionsWithinFilterCount() {
      if (this.activeFilter) {
        return this.activeFilter.options.filter(option => option.active).length;
      }
      return 0;
    },
    activeFilterCount() {
      return this.filtersForEditing.reduce((count, filter) => {
        count += filter.options.filter(option => option.active).length;
        return count;
      }, 0);
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.documentElement.classList.add("overflow-y-hidden");
        document.body.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
        document.body.classList.remove("overflow-y-hidden");
      }
    }
  },
  mounted() {
    this.resultsCount = this.totalCount;
    if (Object.keys(this.filtersForEditing).length === 0) {
      this.filtersForEditing = cloneDeep(this.filters);
    }

    window.addEventListener("updateSort", this.handleChange);
    window.addEventListener("filterResultsUpdated", this.updateFilterResults);
    window.addEventListener("removeActiveFilter", this.removeActiveFilter);
    window.addEventListener("filtersUpdated", this.updateFilters);
    window.addEventListener("showAllFilterDrawer", this.showAllFilterDrawer);
    window.addEventListener("showFilterDrawer", event => {
      this.isOpen = true;
      this.activeFilterDrawerIsOpen = true;
      this.activeFilterTitle = event.detail;
    });
    this.setClearFilterCount();
  },
  methods: {
    showAllFilterDrawer() {
      this.isOpen = true;
      this.activeFilterTitle = "";
    },
    closeDrawers() {
      this.activeFilterDrawerIsOpen = false;
      this.isOpen = false;
    },
    closeActiveDrawer() {
      this.activeFilterDrawerIsOpen = false;
      this.activeFilterTitle = "";
    },
    setActiveFilterTitle(title) {
      analytics.track("Clicked Filter", {
        name: title
      });
      this.activeFilterTitle = title;
      this.activeFilterDrawerIsOpen = true;
      this.setClearFilterCount();
    },
    handleChange(e) {
      this.isLoading = true;
      const hash = this.query ? { q: this.query } : {};
      this.filtersForEditing.forEach(filter => {
        const selectedOptions = filter.options.filter(option => option.active);
        if (selectedOptions.length) {
          if (filter.param === "photo") {
            hash[`${filter.param}`] = selectedOptions.map(
              option => option.value
            );
          } else {
            hash[`${filter.param}[]`] = selectedOptions.map(
              option => option.value
            );
          }
        }
      });

      if (e) {
        hash.sort = e.detail;
      } else {
        hash.sort =
          Object.fromEntries(new URLSearchParams(window.location.search))
            .sort || "favourite";
      }

      const filtersFormElement = document.querySelector(".filters");

      applyFilters(
        hash,
        filtersFormElement.getAttribute("action"),
        !!filtersFormElement.dataset.addToPath
      );
      this.setClearFilterCount();
    },
    clearFilters() {
      this.filtersForEditing.forEach(filter => {
        if (this.activeFilterDrawerIsOpen) {
          if (filter.title === this.activeFilter.title) {
            filter.options.forEach(option => {
              option.active = false;
            });
          }
        } else {
          filter.options.forEach(option => {
            option.active = false;
          });
        }
      });
      this.handleChange();
      this.activeFilter = this.filtersForEditing.find(
        filter => filter.title === this.activeFilter.title
      );
    },
    setClearFilterCount() {
      this.clearFilterCount = 0;
      this.filtersForEditing.forEach(filter => {
        if (this.activeFilter && filter.title === this.activeFilter.title) {
          filter.options.forEach(option => {
            if (option.active) {
              this.clearFilterCount += 1;
            }
          });
        }
      });
    },
    updateFilters(e) {
      this.filtersForEditing = e.detail;
      this.isLoading = false;
    },
    updateFilterResults(e) {
      this.resultsCount = e.detail;
    },
    removeActiveFilter(e) {
      const filterToEdit = this.filtersForEditing.find(
        filter => filter.title === e.detail.filterTitle
      );
      const optionToEdit = filterToEdit.options.find(
        opt => opt.name === e.detail.optionName
      );
      optionToEdit.active = false;
      this.handleChange();
    }
  }
};
</script>

<template>
  <div class="flex mt-auto text-center shadow-2 p-5 md:p-6">
    <btn
      ref="btnClear"
      :data-cy="`clear-${clearFilterCount}-filter`"
      :disabled="buttonShouldBeDisabled"
      type="secondary"
      class="btn-large btn-block mr-4 md:mr-3 cursor-pointer"
      @click="$emit('clearFilters')">
      {{ t("ab-111-filters.clear-button") }}
      <span v-if="activeFilterTitle && clearFilterCount">
        ({{ clearFilterCount }})
      </span>
    </btn>
    <btn
      ref="btnView"
      data-cy="view-filter"
      :disabled="!activeFilterCount"
      type="primary"
      class="btn-large btn-block cursor-pointer"
      @click="$emit('closeDrawers')">
      {{ t("ab-111-filters.view-button") }}
      <span v-if="activeFilterCount">({{ resultsCount }})</span>
    </btn>
  </div>
</template>

<script>
import Btn from "@/app/javascript/components/shared/Btn.vue";

export default {
  name: "FilterButtonControls",
  components: {
    Btn
  },
  props: {
    resultsCount: {
      type: Number,
      required: true
    },
    activeFilterCount: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    clearFilterCount: {
      type: Number,
      required: true
    },
    activeFilterTitle: {
      type: String,
      required: true
    },
    activeOptionsWithinFilterCount: {
      type: Number,
      required: true
    }
  },
  emits: ["clearFilters", "closeDrawers"],
  computed: {
    buttonShouldBeDisabled() {
      if (this.activeFilterTitle && this.activeOptionsWithinFilterCount) {
        return false;
      }
      if (this.activeFilterCount && !this.activeFilterTitle) {
        return false;
      }
      return true;
    }
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.$refs.btnView.showLoader();
        this.$refs.btnClear.showLoader();
      } else {
        this.$refs.btnView.hideLoader();
        this.$refs.btnClear.hideLoader();
      }
    }
  }
};
</script>

import { createApp } from "vue";

import I18n from "../plugins/i18n";
import QuickSampleButton from "../components/shared/QuickSampleButton.vue";
import { on } from "../utils/listeners";

function createQuickSampleApp(
  el = document.querySelector(".quick-sample-button")
) {
  if (el.offsetParent === null) return;
  // eslint-disable-next-line no-underscore-dangle
  if (el.__vue_app__) return;

  const translations = JSON.parse(el.dataset.translations);
  const storeData = JSON.parse(el.dataset.state);

  const app = createApp(QuickSampleButton);
  app.provide(
    "unpersonalisedSampleEnabled",
    storeData.unpersonalisedSampleEnabled
  );
  app.provide("unpersonalisedLink", storeData.unpersonalisedLink);
  app.provide("listingSlug", storeData.listingSlug);
  app.provide("customiseSamplePath", storeData.customiseSamplePath);
  app.provide("showsSamplePack", storeData.showsSamplePack);
  app.provide("samplePackUrl", storeData.samplePackUrl);
  app.provide("pdpPage", storeData.pdpPage);
  app.use(I18n, translations);

  app.mount(el);
}

on("click", ".quick-sample-button", el => {
  createQuickSampleApp(el);
});

on("click", ".order-sample-button", (el, e) => {
  e.preventDefault();
  createQuickSampleApp();
  window.dispatchEvent(new Event("showQuickSampleModal"));
});
